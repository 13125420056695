import { useSearchParams } from "react-router-dom";

export const useUrlParam = (key: string, defaultValue: string = ""): [string, (value: string) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const urlParam = searchParams.get(key) || defaultValue;

    const setUrlParam = (value: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (value) {
            newSearchParams.set(key, value);
        } else {
            newSearchParams.delete(key);
        }
        setSearchParams(newSearchParams);
    };

    return [urlParam, setUrlParam];
};
