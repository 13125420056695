import {
    BatterySaver,
    HelpOutline,
    NotificationImportantOutlined,
    NotificationsOffOutlined,
    NotificationsOutlined,
} from "@mui/icons-material"
import { Box, Chip, IconButton, Stack, Tooltip, Typography, styled, useTheme } from "@mui/material"
import { useSnackbar } from "notistack"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { ItemState, UnitAlarmState } from "../api/Alarm"
import { Operation, asUnitSubject } from "../api/Authz"
import { OfferingType, Unit, UnitType, offeringOperation } from "../api/Customer"
import useAuthorizer, { useUnitPerimeterPermission, useUnitPermission } from "../auth/AuthorizerProvider"
import { eyeTrackEndpointURL, offeringPath, retainConfig } from "../config/urls"
import { ImageWithLoading } from "./ImageWithLoading"
import { ServiceIcon } from "./ServiceIcon"
import { UnitIcon } from "./UnitIcon"
import { UnitMenu } from "./UnitMenu"

const GrantedActionButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.light,
    padding: "7px 7px 7px 7px",
    margin: "1px 1px 1px 1px",
    backgroundColor: "rgba(45, 45, 45, 0.75)",
    fontSize: "small",
    "&:hover": {
        color: theme.palette.grey[200],
        backgroundColor: theme.palette.primary.light,
    },
    "&.Mui-disabled": {
        color: theme.palette.grey[500],
        backgroundColor: "rgba(45, 45, 45, 0.75)",
    },
})) as typeof IconButton

const DeniedActionButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.secondary.light,
    padding: "7px 7px 7px 7px",
    margin: "1px 1px 1px 1px",
    background: "rgba(5, 45, 60, 0.75)",
    "&.Mui-disabled": {
        color: "#ff4848",
        background: "rgba(5, 45, 60, 0.75)",
    },
})) as typeof IconButton

export interface UnitInfoProps {
    unit: Unit
    alarmState: UnitAlarmState
    seen: boolean
    onSetUnitAlarmState: (state: ItemState) => void
    onDisableUnitPowerSave: () => void
}

const offerings = [
    OfferingType.Access,
    OfferingType.Alarm,
    OfferingType.Video,
    OfferingType.Weather,
    OfferingType.Statistics,
    OfferingType.Tracking,
    OfferingType.Timelapse,
]

export function UnitInfo(props: UnitInfoProps) {
    const { unit, alarmState, seen, onSetUnitAlarmState, onDisableUnitPowerSave } = props

    const snackbar = useSnackbar()
    const theme = useTheme()
    const { t } = useTranslation()
    const { allowOperation } = useAuthorizer()

    const allowGetPowerSave = useUnitPermission(Operation.GET_POWER_SAVE, unit)
    const allowDisablePowerSave = useUnitPermission(Operation.DISABLE_POWER_SAVE, unit)
    const allowGetPartition = useUnitPerimeterPermission(Operation.GET_PARTITION, unit)
    const allowArmPartition = useUnitPerimeterPermission(Operation.ARM_PARTITION, unit)
    const allowDisarmPartition = useUnitPerimeterPermission(Operation.DISARM_PARTITION, unit)

    const unitOfferings = useMemo(() => new Set(unit.Offerings?.map((o) => o.DisplayName) || []), [unit])

    const isAuthorized = useMemo(
        () =>
            new Set(
                unit.Offerings?.filter((o) =>
                    allowOperation(offeringOperation(o.DisplayName), asUnitSubject(unit))
                )?.map((o) => o.DisplayName)
            ),
        [unit, allowOperation]
    )

    const unitSnapshot = useMemo(() => {
        if (!isAuthorized.has(OfferingType.Video)) {
            return ""
        }

        return eyeTrackEndpointURL(unit, `cameras/${unit.UnitConfig.SnapshotCameraID || 1}/snapshot?resolution=600x600`)
    }, [isAuthorized, unit])

    const getAlarmStateColor = (state: ItemState) => {
        switch (state) {
            case ItemState.On:
                return "secondary"
            case ItemState.Off:
                return "success"
            case ItemState.Partial:
                return "warning"
            case ItemState.Unknown:
            case ItemState.Empty:
                return "default"
        }
    }

    const getAlarmStateText = (state: ItemState) => {
        switch (state) {
            case ItemState.On:
                return t("alarm.armed")
            case ItemState.Off:
                return t("alarm.disarmed")
            case ItemState.Partial:
                return t("alarm.partial")
            case ItemState.Unknown:
            case ItemState.Empty:
                return t("alarm.unknown")
        }
    }

    const getAlarmStateIcon = (state: ItemState) => {
        switch (state) {
            case ItemState.On:
                return <NotificationsOutlined />
            case ItemState.Off:
                return <NotificationsOffOutlined />
            case ItemState.Partial:
                return <NotificationImportantOutlined />
            case ItemState.Unknown:
            case ItemState.Empty:
                return <HelpOutline />
        }
    }

    const getAlarmStateChip = (
        key: string,
        armTooltip: string,
        disarmTooltip: string,
        state: ItemState,
        onClick: (state: ItemState) => void
    ) => {
        return (
            <Tooltip
                title={t(state === ItemState.On || state === ItemState.Partial ? disarmTooltip : armTooltip)}
                disableInteractive
            >
                <Chip
                    key={key}
                    icon={getAlarmStateIcon(state)}
                    label={getAlarmStateText(state)}
                    color={getAlarmStateColor(state)}
                    onClick={() => onClick(state)}
                    size="small"
                />
            </Tooltip>
        )
    }

    const enableUnitAlarm = () => onSetUnitAlarmState(ItemState.On)
    const disableUnitAlarm = () => onSetUnitAlarmState(ItemState.Off)

    const onUnitAlarmClicked = (state: ItemState) => {
        if (state === ItemState.On) {
            if (!allowDisarmPartition) {
                snackbar.enqueueSnackbar(t("message.forbidden_unit_disarm", { unit: unit.ShortName }), {
                    variant: "warning",
                })
            } else {
                disableUnitAlarm()
            }
        } else {
            if (!allowArmPartition) {
                snackbar.enqueueSnackbar(t("message.forbidden_unit_arm", { unit: unit.ShortName }), {
                    variant: "warning",
                })
            } else {
                enableUnitAlarm()
            }
        }
    }

    const renderUnitArmedState = () => {
        if (!allowGetPartition || alarmState.Armed === ItemState.Empty) {
            return <></>
        }

        return getAlarmStateChip(
            `unit-alarm-state-${unit.ID}`,
            "action.unit_arm_tooltip",
            "action.unit_disarm_tooltip",
            alarmState.Armed || ItemState.Unknown,
            (state) => onUnitAlarmClicked(state)
        )
    }

    const renderUnitPowerSavingState = () => {
        if (!allowGetPowerSave) {
            return <></>
        }
        if (alarmState.PowerSaving !== ItemState.On) {
            return <></>
        }
        const icon = <BatterySaver fontSize="small" htmlColor="orange" />

        return (
            <Tooltip title={t("state.power_saving_enabled")} disableInteractive>
                {allowDisablePowerSave ? (
                    <IconButton
                        size="small"
                        color="info"
                        sx={{ width: 24, height: 24, padding: 0, margin: 0 }}
                        onClick={onDisableUnitPowerSave}
                    >
                        {icon}
                    </IconButton>
                ) : (
                    icon
                )}
            </Tooltip>
        )
    }

    const unitTypeTag = (type: UnitType) => {
        switch (type) {
            case UnitType.City:
                return "City"
            case UnitType.Light:
                return "Light"
            case UnitType.Micro:
                return "Micro"
            case UnitType.Site:
                return "Site"
            case UnitType.Heavy:
                return "Heavy"
            case UnitType.Hub:
                return "Hub"
            default:
                return "SmartOffice"
        }
    }

    const renderUnitType = () => {
        const tag = unitTypeTag(unit.Type)
        if (!tag) {
            return null
        }
        return (
            <Tooltip title={`${t("customer.unit_type")}: ${unit.Type}`} disableInteractive>
                <Stack
                    sx={{
                        height: 16,
                        px: "5px",
                        backgroundColor: "grey",
                        borderRadius: 1,
                        color: "black",
                        mixBlendMode: "screen",
                    }}
                >
                    <Typography variant="caption" align="center" fontSize="11px" fontWeight="bold">
                        {tag}
                    </Typography>
                </Stack>
            </Tooltip>
        )
    }

    return (
        <Box
            key={"unit-panel-" + unit.ID}
            id={unit.ShortName}
            sx={{
                position: "relative",
                backgroundColor: theme.palette.background.default,
                borderStyle: "solid",
                borderColor: theme.palette.panel.border,
                borderWidth: 1,
                boxShadow: 3,
            }}
        >
            <Box sx={{ pl: 0.5, pr: 0.2, py: 0.5, display: "flex", flexGrow: 1 }}>
                <Stack direction="row" width="100%" spacing={0.5} alignItems="center">
                    <UnitIcon unit={unit} />
                    <Typography>{unit.DisplayName}</Typography>
                    {seen && renderUnitArmedState()}
                    {seen && renderUnitPowerSavingState()}
                    <Box flexGrow={1} />
                    <UnitMenu unit={unit} />
                </Stack>
            </Box>
            <Box
                sx={{
                    display: "block",
                    width: "100%",
                    height: "auto",
                    position: "relative",
                    overflow: "hidden",
                    aspectRatio: "16/9",
                }}
            >
                <Box sx={{ background: "transparent", position: "absolute", right: 0, top: 0, m: 0.5 }}>
                    {renderUnitType()}
                </Box>
                {seen && (
                    <ImageWithLoading
                        src={unitSnapshot}
                        alt={unit.DisplayName}
                        errorText={
                            allowGetPowerSave && alarmState.PowerSaving === ItemState.On
                                ? t("message.power_saving")
                                : t("message.no_data")
                        }
                    />
                )}
            </Box>
            <Box sx={{ background: "transparent", position: "absolute", bottom: 0, m: 0.5 }}>
                {offerings.map((offering) => {
                    const authorized = isAuthorized.has(offering)
                    const unauthorized = unitOfferings.has(offering) && !authorized
                    const suffix = authorized
                        ? ""
                        : ` (${t(unauthorized ? "customer.user_not_allowed" : "customer.service_not_subscribed")})`
                    const label = `open ${offering}`
                    const link = offeringPath(unit, offering)
                    return (
                        <Tooltip
                            key={offering}
                            title={`${t("offering." + offering.toLowerCase())}${suffix}`}
                            disableInteractive
                        >
                            {
                                <span>
                                    {unauthorized && (
                                        <DeniedActionButton
                                            aria-label={label}
                                            disabled={!authorized}
                                            to={retainConfig({ pathname: link })}
                                            component={Link}
                                        >
                                            <ServiceIcon offering={offering} />
                                        </DeniedActionButton>
                                    )}
                                    {!unauthorized && (
                                        <GrantedActionButton
                                            aria-label={label}
                                            disabled={!authorized}
                                            to={retainConfig({ pathname: link })}
                                            component={Link}
                                        >
                                            <ServiceIcon offering={offering} />
                                        </GrantedActionButton>
                                    )}
                                </span>
                            }
                        </Tooltip>
                    )
                })}
            </Box>
        </Box>
    )
}
