import { CreditCard, Domain, LockPerson, People } from "@mui/icons-material"
import { Box, IconButton, Paper, Stack, Tooltip, Typography, useTheme } from "@mui/material"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import { Operation } from "../api/Authz"
import { compareUnits, Customer, Site, Unit } from "../api/Customer"
import { useCustomerPermission } from "../auth/AuthorizerProvider"
import { hookTimeout, Milli } from "../config/time"
import { retainConfig } from "../config/urls"
import { SiteInfo } from "./SiteInfo"

export interface CustomerInfoProps {
    customer: Customer
    onManageUsers: () => void
    onManageIdentifiers: () => void
    showArchivedSites: boolean
    archiveSite: (site: Site) => void
}

export function CustomerInfo(props: CustomerInfoProps) {
    const { customer, onManageUsers, onManageIdentifiers, showArchivedSites, archiveSite } = props

    const theme = useTheme()
    const { t } = useTranslation()

    const allowListUsers = useCustomerPermission(Operation.LIST_CUSTOMER_USERS, customer.ID)
    const allowListIdentifiers = useCustomerPermission(Operation.LIST_CUSTOMER_IDENTIFIERS, customer.ID)
    const allowManagePermissions = useCustomerPermission(Operation.UI_VIEW_CUSTOMER_PERMISSIONS, customer.ID)

    const siteUnits = useMemo(() => {
        const result = new Map<number, Unit[]>()
        customer.Units.forEach((u) => {
            const units = result.get(u.SiteID) || []
            units.push(u)
            result.set(u.SiteID, units)
        })
        result.forEach((v) => v.sort(compareUnits))
        return result
    }, [customer])

    const renderSites = useMemo(() => {
        const sites = customer.Sites.filter((s) => showArchivedSites || !s.Archived)
        if (sites.length === 0) {
            return (
                <Stack
                    direction="column"
                    sx={{
                        borderColor: theme.palette.divider,
                        borderWidth: 1,
                        borderTopStyle: "solid",
                    }}
                >
                    <Typography variant="body1" fontStyle="italic" color={theme.palette.text.secondary} p={2}>
                        {t("customer.no_site")}
                    </Typography>
                </Stack>
            )
        }

        return sites.map((s) => (
            <SiteInfo key={s.ID} site={s} units={siteUnits.get(s.ID) || []} archiveSite={archiveSite} />
        ))
    }, [siteUnits, customer, t, theme, showArchivedSites, archiveSite])

    const { hash } = useLocation()

    useEffect(
        () =>
            hookTimeout(() => {
                const el = document.getElementById(hash.substring(1))
                if (!el) {
                    return
                }
                const yCoordinate = el.getBoundingClientRect().top + window.scrollY
                // Offset to move below the toolbar.
                const yOffset = -52
                window.scrollTo({ top: yCoordinate + yOffset })
            }, 300 * Milli),
        [hash]
    )

    return (
        <Paper sx={{ py: 1, m: 1 }}>
            <Stack>
                <Stack id={`customer-${customer.ID}`} direction="row" px={2} spacing={0.5} alignItems="center">
                    <Domain fontSize="small" htmlColor={theme.palette.text.secondary} />
                    <Tooltip title={customer.DisplayName} disableInteractive>
                        <Typography variant="body1" fontWeight="bold" noWrap flexShrink={1}>
                            {customer.DisplayName}
                        </Typography>
                    </Tooltip>
                    <Box flexGrow={1} />
                    <Stack direction="row">
                        <Tooltip title={t("action.manage_identifiers_tooltip")} disableInteractive>
                            <span>
                                <IconButton
                                    color="primary"
                                    onClick={onManageIdentifiers}
                                    disabled={!allowListIdentifiers}
                                >
                                    <CreditCard />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={t("action.manage_users_tooltip")} disableInteractive>
                            <span>
                                <IconButton color="primary" onClick={onManageUsers} disabled={!allowListUsers}>
                                    <People />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={t("action.manage_customer_permissions_tooltip")} disableInteractive>
                            <span>
                                <IconButton
                                    color="primary"
                                    disabled={!allowManagePermissions}
                                    to={retainConfig({ pathname: `./permissions/customers/${customer.ID}` })}
                                    component={Link}
                                >
                                    <LockPerson />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                </Stack>
                {renderSites}
            </Stack>
        </Paper>
    )
}
