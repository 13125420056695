import { ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Day, Hour } from "../../config/time"

export interface Range {
    label: string
    tooltip: string
    millis: number
    stringValue: string
}

export const FourWeeksRange = {
    label: "statistics.range_weeks_4",
    tooltip: "statistics.range_weeks_4_tooltip",
    millis: 28 * Day,
    stringValue: "4w",
}
export const OneWeekRange = {
    label: "statistics.range_week",
    tooltip: "statistics.range_week_tooltip",
    millis: 7 * Day,
    stringValue: "1w",
}
export const ThreeDayRange = {
    label: "statistics.range_days_3",
    tooltip: "statistics.range_days_3_tooltip",
    millis: 3 * Day,
    stringValue: "3d",
}
export const OneDayRange = {
    label: "statistics.range_day",
    tooltip: "statistics.range_day_tooltip",
    millis: Day,
    stringValue: "1d",
}
export const SixHoursRange = {
    label: "statistics.range_hours_6",
    tooltip: "statistics.range_hours_6_tooltip",
    millis: 6 * Hour,
    stringValue: "6h",
}
export const OneHourRange = {
    label: "statistics.range_hour",
    tooltip: "statistics.range_hour_tooltip",
    millis: Hour,
    stringValue: "1h",
}

export const RangeMap: Record<string, Range> = {
    "1h": OneHourRange,
    "6h": SixHoursRange,
    "1d": OneDayRange,
    "3d": ThreeDayRange,
    "1w": OneWeekRange,
    "4w": FourWeeksRange,
}

const shortRanges: Range[] = [OneWeekRange, ThreeDayRange, OneDayRange, SixHoursRange, OneHourRange]
const longRanges: Range[] = [FourWeeksRange, OneWeekRange, ThreeDayRange, OneDayRange, SixHoursRange, OneHourRange]

export interface RangeChoiceProps {
    value: Range
    long?: boolean
    onChange: (range: Range) => void
}

export const RangeChoice = (props: RangeChoiceProps) => {
    const { value, long, onChange } = props
    const { t } = useTranslation()
    const ranges = long ? longRanges : shortRanges

    return (
        <ToggleButtonGroup value={value} exclusive={true} onChange={(_, v) => v && onChange(v)}>
            {ranges.map((r) => (
                <Tooltip title={t(r.tooltip)}>
                    <ToggleButton key={r.label} sx={{ padding: "0.5em" }} size="small" value={r} selected={r === value}>
                        <Typography variant="caption">{t(r.label)}</Typography>
                    </ToggleButton>
                </Tooltip>
            ))}
        </ToggleButtonGroup>
    )
}
