import { Box } from "@mui/material"
import { useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import useAuth from "../auth/AuthProvider"
import { LoginDialog } from "../components/LoginDialog"
import useConfig from "../config/Provider"
import { retainConfig, staticURL, urlTo } from "../config/urls"
import { useUrlParam } from "../hooks/useurlparam"

export const Login = () => {
    const [messages, setMessages] = useState<string[]>([])

    const [redirectInput] = useUrlParam("redirect_to")
    const redirectTo = redirectInput && redirectInput.startsWith("/") ? redirectInput : "/"
    const { whoAmI, login } = useAuth()
    const navigate = useNavigate()
    const { mode } = useConfig()

    return (
        <Box
            width="100%"
            height="100%"
            style={{
                backgroundImage: `url(${staticURL(mode === "dark" ? "background.jpg" : "background-light.jpg")}`,
                backgroundSize: "cover",
            }}
        >
            {!whoAmI ? (
                // If there is no actor, let user log in.
                <LoginDialog
                    messages={messages}
                    onLogin={(email, password) => login(email, password, setMessages, navigate, redirectTo)}
                />
            ) : (
                <Navigate to={retainConfig(urlTo(redirectTo))} replace />
            )}
        </Box>
    )
}
