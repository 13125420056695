import { Typography } from "@mui/material"
import { RedirectDialog } from "../components/ResetDialog"
import { useUrlParam } from "../hooks/useurlparam"

export const Reset = () => {
    const [token] = useUrlParam("token")
    const url = token ? atob(token) : ""

    if (!url) {
        return <Typography>Missing reset token.</Typography>
    }

    return <RedirectDialog url={url} />
}
