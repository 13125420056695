import { useEffect, useRef, useState } from "react"
import { hookTimeout } from "../config/time"

export const useSeen = (): [React.RefObject<HTMLDivElement>, boolean] => {
    const ref = useRef<HTMLDivElement | null>(null)
    const [seen, setSeen] = useState(false)

    useEffect(() => {
        const element = ref.current
        if (!element) {
            return
        }
        if (seen) {
            return
        }

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setSeen(true)
                observer.unobserve(entry.target)
            }
        }, { rootMargin: "100px" })

        // TODO(osery): We need to set the observer in a timeout (after all other scheduled events)
        // in case the url has a #hash in it, in which case observer seems to miss the initial page
        // scroll.
        const cancel = hookTimeout(() => observer.observe(element), 0)

        return () => {
            cancel()
            observer.disconnect()
        }
    }, [seen])

    return [ref, seen]
}
