import {
    BatteryAlert,
    BatterySaver,
    CloudOff,
    DeviceThermostat,
    DirectionsRun,
    EmojiPeople,
    Face,
    FileDownload,
    FileUpload,
    FlashlightOff,
    FlashlightOn,
    FontDownloadOffOutlined,
    FontDownloadOutlined,
    Grid4x4,
    Hardware,
    Help,
    LocalGasStation,
    MeetingRoom,
    NoMeetingRoom,
    NotificationsActive,
    NotificationsOffOutlined,
    NotificationsOutlined,
    Pentagon,
    PentagonOutlined,
    PlayCircle,
    Power,
    PowerOff,
    ReplayCircleFilled,
    RestartAlt,
    SensorOccupied,
    Sensors,
    Sync,
    SyncDisabled,
    SyncProblem,
    VerticalAlignCenter,
} from "@mui/icons-material"
import { TFunction } from "i18next"
import { ReactElement } from "react"
import { http, noSnackBar } from "../backend/request"
import { request } from "../config/headers"
import { TimeRange } from "../config/time"
import { monoEndpointURL } from "../config/urls"
import { PaginationResponse } from "./Pagination"

export type Labels = Map<string, string>
export type Annotations = Map<string, string>

export type EventType = {
    cls: string
    item: string
    label: string
    value: number
    id: string
}

export const EventTypeID = (cls: string, item: string, value: number) => `${cls}:${item}:${value}`
export const newClass = (cls: string) => (item: string, label?: string, value?: number) =>
    newType(cls, item, label, value)
export const newType = (cls: string, item: string, label?: string, value?: number): EventType => {
    const v = value === undefined ? 1 : value
    return {
        cls: cls,
        item: item,
        label: label || `event.${item}`,
        value: v,
        id: EventTypeID(cls, item, v),
    }
}

const OnvifClass = newClass("onvif_events")
export const OnvifMotionAlarm = OnvifClass("motion_alarm")
export const OnvifCellMotionDetector = OnvifClass("cell_motion_detector")
export const OnvifFaceDetector = OnvifClass("face_detector")
export const OnvifFieldDetectorInside = OnvifClass("field_detector_inside")
export const OnvifFieldDetectorOutside = OnvifClass("field_detector_outside")
export const OnvifHumanDetector = OnvifClass("human_detector")
export const OnvifLineDetector = OnvifClass("line_detector")
export const OnvifLoiteringDetector = OnvifClass("loitering_detector")
export const OnvifMotionDetector = OnvifClass("motion_detector")
export const OnvifObjectDetectorLeft = OnvifClass("object_detector_left")
export const OnvifObjectDetectorRemoved = OnvifClass("object_detector_removed")
export const OnvifTamperDetector = OnvifClass("tamper_detector")

const PartitionClass = newClass("alarm_partition")
export const PartitionAlarm = PartitionClass("alarm", "event.partition_alarm")
export const PartitionArmed = PartitionClass("armed", "event.partition_armed")
export const PartitionDisarmed = PartitionClass("armed", "event.partition_disarmed", 0)

const ZoneClass = newClass("alarm_zone")
export const ZoneAlarm = ZoneClass("alarm", "event.zone_alarm")
export const ZoneViolation = ZoneClass("violation", "event.zone_violation")
export const ZoneLowBattery = ZoneClass("low_battery", "event.zone_low_battery")
export const ZoneDisconnected = ZoneClass("disconnected", "event.zone_disconnected")

const SystemClass = newClass("system")
export const SystemExternalPowerOn = SystemClass("external_power", "event.external_power_on")
export const SystemExternalPowerOff = SystemClass("external_power", "event.external_power_off", 0)
export const SystemPowercut = SystemClass("powercut")

const GeneratorClass = newClass("generator")
export const GeneratorStarted = GeneratorClass("running", "event.generator_started")
export const GeneratorStopped = GeneratorClass("running", "event.generator_stopped", 0)
export const GeneratorFailure = GeneratorClass("failure", "event.generator_failure")
export const GeneratorLowFuel = GeneratorClass("low_fuel", "event.generator_low_fuel")
export const GeneratorNoFuel = GeneratorClass("no_fuel", "event.generator_no_fuel")
export const GeneratorHighTemperature = GeneratorClass("high_temperature", "event.generator_high_temperature")
export const GeneratorManualRestart = GeneratorClass("restart", "event.generator_manually_restarted")
export const GeneratorManualStart = GeneratorClass("start", "event.generator_manually_started")
export const GeneratorModeAutomatic = GeneratorClass("automatic", "event.generator_mode_automatic")
export const GeneratorModeManual = GeneratorClass("automatic", "event.generator_mode_manual", 0)

const PowerSavingClass = newClass("power_saving")
export const PowerSavingLevel1On = PowerSavingClass("level1", "event.power_saving_on")
export const PowerSavingLevel1Off = PowerSavingClass("level1", "event.power_saving_off", 0)

const LightClass = newClass("light")
export const LightOn = LightClass("switch", "event.light_on")
export const LightOff = LightClass("switch", "event.light_off", 0)

const GateClass = newClass("gate")
export const GateOpen = GateClass("switch", "event.gate_open")
export const GateClosed = GateClass("switch", "event.gate_closed", 0)

export const MaxEvents = 5000

export interface Event {
    timestamp: Date
    labels?: Labels
    item: string
    thing: string
    value?: number
    clientId: string
    message: string
    color: string
    icon: ReactElement
}

export interface ListEventsResponse {
    data: EventPayload[]
    pagination: PaginationResponse
}

export interface EventPayload {
    value?: ValueEvent
}

export interface ValueEvent {
    thing: string
    item: string
    value: value
}

interface value {
    labels: Object
    clientId: string
    timestamp: string
    operation: string
    number?: number
    text?: string
}

export enum Direction {
    FORWARD = "forward",
    BACKWARD = "backward",
}

export const loadEvents = (
    siteID: number,
    unit: string | undefined,
    range: TimeRange,
    direction: Direction,
    t: TFunction
): Promise<{ events: Event[]; continuation: string }> => {
    const query = new URLSearchParams()
    unit && query.append("unit", unit)
    range.end && query.append("end", `${new Date(range.end).toISOString()}`)
    query.append("start", `${new Date(range.start).toISOString()}`)
    query.append("limit", `${MaxEvents}`)
    direction !== Direction.BACKWARD && query.append("direction", direction)

    const url = `${monoEndpointURL(`sites/${siteID}/events`)}?${query.toString()}`

    return http<ListEventsResponse>(
        `Loading ${unit ? `unit (${unit})` : `site`} events`,
        url,
        noSnackBar,
        request
    ).then((s) => extractStreams(s, t))
}

const extractStreams = (response: ListEventsResponse, t: TFunction): { events: Event[]; continuation: string } => {
    if (response.data === undefined) {
        response.data = []
    }
    return {
        events: response.data.filter((e) => e.value?.value?.operation === "CHANGED").map((e) => extractEvent(e, t)),
        continuation: response.pagination?.continuation || "",
    }
}

const extractEvent = (o: EventPayload, t: TFunction): Event => {
    const thing = o.value?.thing || ""
    const item = o.value?.item || ""

    const timestamp = new Date(o.value?.value.timestamp || 0)
    const value = o.value?.value.number || 0
    const labels = asLabels(o.value?.value.labels || {})
    const clientId = o.value?.value.clientId || ""
    const details = ToEventDetails(thing, item, value, labels, t)

    return {
        ...details,
        thing,
        item,
        value,
        timestamp,
        labels,
        clientId,
    }
}

export const ToEventDetails = (
    thing: string,
    item: string,
    value: number | undefined,
    labels: Labels | undefined,
    t: TFunction
) => {
    const cls = labels?.get("class") || ""
    const unit = labels?.get("unit") || ""

    switch (cls) {
        case "onvif_events":
            const camera = thing.replace(/.*\.camera_([^.]*).*/, "$1")
            switch (item) {
                case "motion_alarm":
                    return {
                        message: t("event.motion_alarm_message", { camera, unit }),
                        color: "gray",
                        icon: <DirectionsRun />,
                    }
                case "motion_detector":
                    return {
                        message: t("event.motion_detector_message", { camera, unit }),
                        color: "gray",
                        icon: <DirectionsRun />,
                    }
                case "cell_motion_detector":
                    return {
                        message: t("event.cell_motion_detector_message", { camera, unit }),
                        color: "gray",
                        icon: <Grid4x4 />,
                    }
                case "field_detector_inside":
                    return {
                        message: t("event.field_detector_inside_message", { camera, unit }),
                        color: "gray",
                        icon: <Pentagon />,
                    }
                case "field_detector_outside":
                    return {
                        message: t("event.field_detector_outside_message", { camera, unit }),
                        color: "gray",
                        icon: <PentagonOutlined />,
                    }
                case "object_detector_left":
                    return {
                        message: t("event.object_detector_left_message", { camera, unit }),
                        color: "gray",
                        icon: <FileDownload />,
                    }
                case "object_detector_removed":
                    return {
                        message: t("event.object_detector_removed_message", { camera, unit }),
                        color: "gray",
                        icon: <FileUpload />,
                    }
                case "face_detector":
                    return {
                        message: t("event.face_detector_message", { camera, unit }),
                        color: "gray",
                        icon: <Face />,
                    }
                case "human_detector":
                    return {
                        message: t("event.human_detector_message", { camera, unit }),
                        color: "gray",
                        icon: <EmojiPeople />,
                    }
                case "tamper_detector":
                    return {
                        message: t("event.tamper_detector_message", { camera, unit }),
                        color: "gray",
                        icon: <Hardware />,
                    }
                case "loitering_detector":
                    return {
                        message: t("event.loitering_detector_message", { camera, unit }),
                        color: "gray",
                        icon: <SensorOccupied />,
                    }
                case "line_detector":
                    return {
                        message: t("event.line_detector_message", { camera, unit }),
                        color: "gray",
                        icon: <VerticalAlignCenter />,
                    }
            }
            break
        case "alarm_partition":
            const system = labels?.get("member_system") === "true"
            const partition = thing.replace(/.*\.partition_([^.]*).*/, "$1")
            switch (item) {
                case "alarm":
                    return {
                        message: t(system ? "event.system_partition_alarm_message" : "event.partition_alarm_message", {
                            partition,
                            unit,
                        }),
                        color: "red",
                        icon: <NotificationsActive />,
                    }
                case "armed":
                    var message: string
                    if (value === 1) {
                        if (system) {
                            message = "event.system_partition_armed_message"
                        } else {
                            message = "event.partition_armed_message"
                        }
                    } else {
                        if (system) {
                            message = "event.system_partition_disarmed_message"
                        } else {
                            message = "event.partition_disarmed_message"
                        }
                    }
                    return {
                        message: t(message, { partition, unit }),
                        color: value === 1 ? "red" : "#09c809",
                        icon: value === 1 ? <NotificationsOutlined /> : <NotificationsOffOutlined />,
                    }
            }
            break
        case "alarm_zone":
            const zone = thing.replace(/.*\.zone_/, "")
            switch (item) {
                case "violation":
                    return {
                        message: t("event.zone_violation_message", { zone, unit }),
                        color: "gray",
                        icon: <Sensors />,
                    }
                case "alarm":
                    return {
                        message: t("event.zone_alarm_message", { zone, unit }),
                        color: "orange",
                        icon: <NotificationsActive />,
                    }
                case "low_battery":
                    return {
                        message: t("event.zone_low_battery_message", { zone, unit }),
                        color: "orange",
                        icon: <BatteryAlert />,
                    }
                case "disconnected":
                    return {
                        message: t("event.zone_disconnected_message", { zone, unit }),
                        color: "orange",
                        icon: <CloudOff />,
                    }
            }
            break
        case "system":
            switch (item) {
                case "external_power":
                    return {
                        message: t(
                            value === 1 ? "event.external_power_on_message" : "event.external_power_off_message",
                            { unit }
                        ),
                        color: value === 1 ? "#09c809" : "orange",
                        icon: value === 1 ? <Power /> : <PowerOff />,
                    }
                case "powercut":
                    return {
                        message: t("event.powercut_message", { unit }),
                        color: "#09c809",
                        icon: <RestartAlt />,
                    }
            }
            break
        case "generator":
            switch (item) {
                case "running":
                    return {
                        message: t(
                            value === 1 ? "event.generator_started_message" : "event.generator_stopped_message",
                            { unit }
                        ),
                        color: "gray",
                        icon: value === 1 ? <Sync /> : <SyncDisabled />,
                    }
                case "failure":
                    return {
                        message: t("event.generator_failure_message", { unit }),
                        color: "orange",
                        icon: <SyncProblem />,
                    }
                case "low_fuel":
                    return {
                        message: t("event.generator_low_fuel_message", { unit }),
                        color: "orange",
                        icon: <LocalGasStation />,
                    }
                case "no_fuel":
                    return {
                        message: t("event.generator_no_fuel_message", { unit }),
                        color: "red",
                        icon: <LocalGasStation />,
                    }
                case "high_temperature":
                    return {
                        message: t("event.generator_high_temperature_message", { unit }),
                        color: "orange",
                        icon: <DeviceThermostat />,
                    }
                case "restart":
                    return {
                        message: t("event.generator_manually_restarted_message", { unit }),
                        color: "gray",
                        icon: <ReplayCircleFilled />,
                    }
                case "start":
                    return {
                        message: t("event.generator_manually_started_message", { unit }),
                        color: "gray",
                        icon: <PlayCircle />,
                    }
                case "automatic":
                    return {
                        message: t(
                            value === 1
                                ? "event.generator_mode_automatic_message"
                                : "event.generator_mode_manual_message",
                            { unit }
                        ),
                        color: "gray",
                        icon: value === 1 ? <FontDownloadOutlined /> : <FontDownloadOffOutlined />,
                    }
            }
            break
        case "power_saving":
            switch (item) {
                case "level1":
                    return {
                        message: t(value === 1 ? "event.power_saving_on_message" : "event.power_saving_off_message", {
                            unit,
                        }),
                        color: value === 1 ? "orange" : "gray",
                        icon: <BatterySaver />,
                    }
            }
            break
        case "light":
            switch (item) {
                case "switch":
                    return {
                        message: t(value === 1 ? "event.light_on_message" : "event.light_off_message", { unit }),
                        color: value === 1 ? "orange" : "gray",
                        icon: value === 1 ? <FlashlightOn /> : <FlashlightOff />,
                    }
            }
            break
        case "gate":
            const gate = thing.replace(/.*\.gate_/, "")
            switch (item) {
                case "switch":
                    return {
                        message: t(value === 1 ? "event.gate_open_message" : "event.gate_closed_message", {
                            unit,
                            gate,
                        }),
                        color: value === 1 ? "#09c809" : "gray",
                        icon: value === 1 ? <MeetingRoom /> : <NoMeetingRoom />,
                    }
            }
            break
    }
    return {
        message: t("event.unknown_event_message", { thing: thing, item: item, value: value }),
        color: "gray",
        icon: <Help />,
    }
}

export const asLabels = (o: any): Labels | undefined =>
    o === undefined ? undefined : new Map<string, string>(Object.entries(o))
export const asAnnotations = (o: any): Annotations | undefined =>
    o === undefined ? undefined : new Map<string, string>(Object.entries(o))
